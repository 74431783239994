
<template>
  <v-app >
     <v-main>
        <v-container fluid fill-height>
           <v-layout align-center justify-center>
              <v-flex xs12 sm8 md4>
                 <v-card class="elevation-12">
                    <v-toolbar color="primary" dark flat>
                       <v-toolbar-title>{{isRegister ? stateObj.register.name : stateObj.login.name}}</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                      <form ref="form" @submit.prevent="isRegister ? register() : login()">
                          <v-text-field v-if="isRegister" v-model="name" name="name" label="Nombre" type="text" placeholder="Nombre" required></v-text-field>
                          <v-text-field v-model="username" name="username" label="Usuario" type="text" placeholder="Usuario" required></v-text-field>
                          <v-text-field v-model="password" name="password" label="Password" type="password" placeholder="Password" required></v-text-field>
                          <v-text-field v-if="isRegister" v-model="confirmPassword" name="confirmPassword" label="Confirmar Password" type="password" placeholder="Confirmar Password" required></v-text-field>
                          <div class="red--text"> {{errorMessage}}</div>
                          <v-btn type="submit" class="mt-4" color="primary" value="log in">{{isRegister ? stateObj.register.name : stateObj.login.name}}</v-btn>
                          <div class="grey--text mt-4" v-on:click="isRegister = !isRegister;">{{toggleMessage}}</div>
                     </form>
                    </v-card-text>
                 </v-card>
              </v-flex>
           </v-layout>
        </v-container>
     </v-main>
  </v-app>
</template>

<script>
import axios from 'axios'
import CryptoJS from 'crypto-js'
export default {
 name: "App",
 data() {
   return {
     name:"",
     username: "",
     password: "",
     confirmPassword: "",
     isRegister : false,
     errorMessage: "",
     stateObj: {
        register :{
           name: 'Register',
           message: 'Aleady have an Acoount? login.'
        },
        login : {
           name: 'Login',
           message: 'Register'
        }
     }
   };
 },
 methods: {
   login() {
      let json={"funcion":2,"usuario":this.username,"password":this.password};
            axios.post("https://cobolintegra.com/api/api.php",json)
            .then(data=>{
               if(data.data[0]!=null)
               {
               localStorage.a=CryptoJS.AES.encrypt(data.data[0], "S3gur1d4dc0b0l").toString();
               localStorage.b=CryptoJS.AES.encrypt(data.data[1], "S3gur1d4dc0b0l").toString();
               localStorage.c=CryptoJS.AES.encrypt(data.data[2], "S3gur1d4dc0b0l").toString();
               this.username="",this.password="",this.confirmPassword="",this.errorMessage="",
               this.$router.push('/domoticas');
               }
               else
               {
                  alert("Datos no validos")
                  this.username=""
                  this.password=""
               }
            })
                .catch(error => {
                this.texto = "Validar información"
                this.mensaje=true
                console.log(error)
                }
                )
              
   },
   register() {
      if(this.password == this.confirmPassword){
        let json={"funcion":1,"nombre":this.name,"usuario":this.username,"password":this.password};
            console.log(json)
            axios.post("https://cobolintegra.com/api/api.php",json)
            .then(data=>{
              console.log(data)
                this.name="",this.username="",this.password="",this.confirmPassword="",this.isRegister=false,this.errorMessage=""
            })
                .catch(error => {
                this.texto = "Validar información"
                this.mensaje=true
                console.log(error)
                }
                )
              }
      else {
        this.errorMessage = "password did not match"
      }
   }
 },
     computed: {
      toggleMessage : function() { 
         return this.isRegister ? this.stateObj.register.message : this.stateObj.login.message }
   }
};
</script>
