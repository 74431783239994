
<template>
    <div class="d-flex flex-column h-100 w-100 pa-2">
		<div>
    		<nav class="navbar navbar-inverse">
      			<div class="container-fluid">
					<div class="navbar-header">
						<a class="navbar-brand"  v-show="enable" @click="nuevo()">Nuevo Equipo</a>
					</div>
					<div class="navbar-header navbar-right">
						<a class="navbar-brand" v-on:click="salir()" >Salir</a>
    				</div>
 				</div>
			</nav>
    		<hr>
			<div v-show="enable">
				<div class="container-fluid">{{ this.cliente }}</div>
				<v-card class="mx-auto my-8" elevation="2" max-width="100%" v-for="item in items" :key="item.id">
						<v-card-title>{{ item.nombre }}</v-card-title>
						<div class="d-flex justify-center ga-2">
							<v-chip class="ma-3" color="success" variant="outlined" v-on:click="editar(item.nombre,item.identificacion)">Modificar</v-chip>
							<v-chip class="ma-3" color="primary" variant="outlined" v-on:click="eliminar(item.nombre)">Eliminar</v-chip>
						</div>
				</v-card>
			</div>
			<div v-show="!enable">
				<table id="datos" class="table" style="font-size:small">
					<thead>
						<tr>
							<th><input v-model="identificacion" type="text" class="form-control" placeholder="Identificacion" v-bind:disabled="id"></th>
							<th><input v-model="name" type="text" class="form-control" placeholder="Nombre" v-bind:disabled="nom"></th>
						</tr>
					</thead>
				</table>
				<table id="tabla" class="table" style="font-size:small">
					<thead>
						<tr>
							<th><i><img  src="../assets/reloj.png" width="auto" height="25" alt=""></i></th>
							<th><i><img  src="../assets/aire.png" width="auto" height="25" alt=""></i></th>
							<th><i><img  src="../assets/ventilador.png" width="auto" height="30" alt=""></i></th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(pr,index) in hr" :key="pr.i">
							<th><input type="time" v-model="hr[index]" class="form-control"></th>
							<th><v-switch  v-model=ai[index]></v-switch></th>
							<th><v-switch  v-model=vt[index]></v-switch></th>
							<th><v-chip class="ma-3" color="red" text-color="white" variant="outlined" @click="borrar(index)">Borrar</v-chip></th>
                        </tr>
					</tbody>
				</table>
				<v-chip class="ma-3" color="primary" variant="outlined"  @click="agregar()">Agregar</v-chip>			
			  	<v-chip class="ma-3" color="success" variant="outlined"  @click="guardar()">Guardar</v-chip>
			</div>
    </div>
  </div>

</template>

<script>
import axios from 'axios'
import CryptoJS from 'crypto-js'
export default {
 name: "App",
 data() {
   return {
	cliente:"",
	datos:0,
	identificacion:"",
	name:"",
	items:[],
	horarios:[],horario:[],nombre:[],hr:[],vt:[],ai:[],
	isEnabled:1,enable:1,
    activar: 0,
	tiempo:"",
	id:true,
	nom:true,
	estado:0,
    }
 },
 mounted(){
	this.iniciar()
 },
 methods: {
		iniciar()
		{
			let token=(CryptoJS.AES.decrypt(localStorage.a,"S3gur1d4dc0b0l").toString(CryptoJS.enc.Utf8));
		this.cliente=(CryptoJS.AES.decrypt(localStorage.b,"S3gur1d4dc0b0l").toString(CryptoJS.enc.Utf8));
		let usuario=(CryptoJS.AES.decrypt(localStorage.c,"S3gur1d4dc0b0l").toString(CryptoJS.enc.Utf8));
		let json={"funcion":3,"token":token,"usuario":usuario};
            axios.post("https://cobolintegra.com/api/api.php",json)
            .then(data=>{
			this.items=data.data
			for (var t =0 ; t < this.items.length; t++) 
            {
            this.horario.push(this.items[t].horario)
			this.nombre.push(this.items[t].nombre)
            }
            })
                .catch(error => {
                this.texto = "Validar información"
                this.mensaje=true
                console.log(error)
                }
                )
		},
		editar(nm,id)
        {
			this.estado=0
			this.id=true
			this.nom=true
			this.identificacion=id
			this.name=nm
			let programacion=this.horario[this.nombre.indexOf(nm)]
			let qty=programacion.length/6
			for (var a =0 ; a < qty; a++) 
			{
				this.hr.push(programacion.substring((a*6),(a*6)+2)+":"+programacion.substring((a*6)+2,((a*6)+2)+2))
				if((programacion.substring((a*6)+4,(a*6)+4+1))==1){this.ai.push(true)}else{this.ai.push(false)}
				if((programacion.substring((a*6)+5,(a*6)+5+1))==1){this.vt.push(true)}else{this.vt.push(false)}
			}
        	this.enable=0
        },
		cancelar()
        {
          this.enable=1
        },
		salir()
        {
			this.id=false
			this.nom=false
			this.activar=1
          if (this.enable==0)
		  {
			this.hr=[],this.vt=[],this.ai=[],
			this.enable=1
		  }
		  else
		  {
			this.$router.push('/');
		  }
        },
		borrar(indice)
		{
			this.hr.splice(indice,1)
			this.ai.splice(indice,1)
			this.vt.splice(indice,1)
		},
		agregar()
		{
			this.hr.push("")
			this.ai.push(false)
			this.vt.push(false)
		},
		guardar()
		{
		let cadena=""
		let stat=0
		for (var b =0 ; b < this.hr.length; b++)
		{
			cadena=cadena+this.hr[b].replace(":","")+Number(this.ai[b])+Number(this.vt[b])
		}
		if (this.estado==1)
		{
			if(this.identificacion.length>0 && this.name.length>0 && cadena.length>0)
			{stat=5}else{alert("faltan datos")}

		}
		else
		{stat=6}
		let token=(CryptoJS.AES.decrypt(localStorage.a,"S3gur1d4dc0b0l").toString(CryptoJS.enc.Utf8));
		let usuario=(CryptoJS.AES.decrypt(localStorage.c,"S3gur1d4dc0b0l").toString(CryptoJS.enc.Utf8));
		let json={"funcion":stat,"identificacion":this.identificacion,"nombre":this.name,"cadena":cadena,"token":token,"usuario":usuario};
		axios.post("https://cobolintegra.com/api/api.php",json)
            .then(data=>{
				this.items=data.data
			this.horario=[],this.nombre=[]
			for (var t =0 ; t < this.items.length; t++) 
            {
            this.horario.push(this.items[t].horario)
			this.nombre.push(this.items[t].nombre)
            }
			this.hr=[]
			this.vt=[]
			this.ai=[]
			this.enable=1
            })
                .catch(error => {
                this.texto = "Validar información"
                this.mensaje=true
                console.log(error)
                }
                )

	},	
		eliminar(nm)
		{
		let text = "Desea eliminar este dispositivo";
		if (confirm(text) == true) {
		let token=(CryptoJS.AES.decrypt(localStorage.a,"S3gur1d4dc0b0l").toString(CryptoJS.enc.Utf8));
		let usuario=(CryptoJS.AES.decrypt(localStorage.c,"S3gur1d4dc0b0l").toString(CryptoJS.enc.Utf8));
		let json={"funcion":4,"dispositivo":nm,"token":token,"usuario":usuario};
            axios.post("https://cobolintegra.com/api/api.php",json)
            .then(data=>{
			this.items=data.data
			for (var t =0 ; t < this.items.length; t++) 
            {
            this.horario.push(this.items[t].horario)
			this.nombre.push(this.items[t].nombre)
            }
            })
                .catch(error => {
                this.texto = "Validar información"
                this.mensaje=true
                console.log(error)
                }
                )
		} 
		else {
			text = "You canceled!";
		}
 		},
		nuevo()
		{
			this.identificacion=''
			this.name=''
			this.estado=1,
			this.id=false,
			this.nom=false,
			this.enable=0
			this.hr=[]
			this.vt=[]
			this.ai=[]			
		}					
 },
     computed: {
      toggleMessage : function() { 
         return this.isRegister ? this.stateObj.register.message : this.stateObj.login.message }
   }
};
</script>